const routes = [
    {
        path: "/40/jgg",
        children: [
            {
                path: "40_20241104hsl1/:test?",
                component: () => import("@/views/40/jgg/20241104/index.vue"),
                meta: {
                    sign: "40_20241104hsl1",
                },
            },
            {
                path: "40_20241104ft2/:test?",
                component: () => import("@/views/40/jgg/20241104/index.vue"),
                meta: {
                    sign: "40_20241104ft2",
                },
            },
            {
                path: "40_20250317jxp/:test?",
                component: () => import("@/views/40/jgg/20250317/index.vue"),
                meta: {
                    sign: "40_20250317jxp",
                },
            },
            {
                path: "40_20250317zg/:test?",
                component: () => import("@/views/40/jgg/20250317/index.vue"),
                meta: {
                    sign: "40_20250317zg",
                },
            },
        ],
    },
];

export default routes;
